import * as React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import Arrow from '../images/down-arrow.svg';

import './hero.scss';

SwiperCore.use([Autoplay]);

const Hero = () => {
  const { t } = useTranslation();

  return (<div id="hero" className="Hero">
    <Swiper className="Swiper" autoplay speed={700} allowTouchMove={false} slidesPerView={1}>
      <SwiperSlide className={`Slide Slide-1`}>
        <h3>{t(`hero.slide1.line1`)}<br/>{t(`hero.slide1.line2`)}</h3>
      </SwiperSlide>

      <SwiperSlide className={`Slide Slide-2`}>
        <h3>{t(`hero.slide2.line1`)}<br/>{t(`hero.slide2.line2`)}</h3>
      </SwiperSlide>

      <SwiperSlide className={`Slide Slide-3`}>
        <h3>{t(`hero.slide3.line1`)}<br/>{t(`hero.slide3.line2`)}</h3>
      </SwiperSlide>
    </Swiper>

    <AnchorLink className="down-arrow" href='#about'>
      <img alt="Scroll down" src={Arrow} />
    </AnchorLink>
  </div>);
};

export default Hero;
