import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import './brochure.scss';

const Brochure = () => {
  const { t, i18n } = useTranslation();
  const url = `pdf/Brochure-Infocus-BT_${i18n.language}.pdf`;
  const name = `Brochure Infocus BT - ${i18n.language.toUpperCase()}.pdf`;

  return (<section className="Brochure">
    <h2>{t('brochure.title')}</h2>
    <a href={url} rel="noopener noreferrer" target="_blank" download={name}>
      {t('brochure.cta')}
    </a>
  </section>);
};

export default Brochure;
