import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import cms from '../cms/team.yml';

import './team.scss';

const Team = () => {
  const { t, i18n } = useTranslation();

  return (<section id='team' className="Team">
    <h2>{t('team.title')}</h2>
    <ul>
      {cms.team.map((person, idx) => (<li key={idx}>
        <a rel="noopener noreferrer" target="_blank" href={person.url}>
          <img alt={person.name} src={`/images/team/${person.picture}`} />
          <h3>{person.name}</h3>
          <h4>{person[`role_${i18n.language}`]}</h4>
          <h5>{person[`title_${i18n.language}`]}</h5>
        </a>
      </li>))}
    </ul>
  </section>);
};

export default Team;
