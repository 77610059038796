import React, { useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Image1 from '../images/services_01.png';
import Image2 from '../images/services_02.png';
import Image3 from '../images/services_03.png';
import CloseIcon from '../images/close.svg';

import './services.scss';

const Service = ({ service, img }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  return (<div className='Service'>
    <img alt={t(`services.${service}.title`)} src={img}></img>

    <h3>{t(`services.${service}.title`)}</h3>
    <p>{t(`services.${service}.description`)}</p>

    <button onClick={() => setExpanded(true)}>{t('services.read_more')}</button>

    {expanded && (
      <div className='Modal'>
        <div className="Modal__Content">
          <h2>{t(`services.${service}.title`)}</h2>
          <h3>{t(`services.${service}.description`)}</h3>

          <img alt={t(`services.${service}.title`)} src={img}></img>

          <p>{t(`services.${service}.text`)}</p>
        </div>

        <button onClick={() => setExpanded(false)}><img alt="Close" src={CloseIcon}></img></button>
      </div>
    )}
  </div>);
};

const Services = () => {
  const { t } = useTranslation();

  return (<section id='services' className="Services">
    <h2>{t('services.title')}</h2>

    <Service service='integration' img={Image1} />
    <Service service='analysis' img={Image2} />
    <Service service='minery' img={Image3} />
  </section>);
};

export default Services;
