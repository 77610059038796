import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import TechnologyImage1 from '../images/pentaho.png';
import TechnologyImage2 from '../images/saiku.png';
import TechnologyImage3 from '../images/tableau.png';
import TechnologyImage4 from '../images/powerbi.png';

import './technologies.scss';

const Technologies = () => {
  const { t } = useTranslation();

  return (<section id="technologies" className="Technologies">
    <h2>{t('technologies.title')}</h2>

    <a rel="noopener noreferrer" target="_blank" href="http://www.pentaho.com/">
      <img alt="Pentaho" src={TechnologyImage1} />
    </a>

    <a rel="noopener noreferrer" target="_blank" href="http://www.meteorite.bi/products/saiku">
      <img alt="Saiku" src={TechnologyImage2} />
    </a>

    <a rel="noopener noreferrer" target="_blank" href="https://www.tableau.com/">
      <img alt="Tableau" src={TechnologyImage3} />
    </a>

    <a rel="noopener noreferrer" target="_blank" href="https://powerbi.microsoft.com/">
      <img alt="Power BI" src={TechnologyImage4} />
    </a>
  </section>);
};

export default Technologies;
