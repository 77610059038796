import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import cms from '../cms/clients.yml';

import './clients.scss';

const Clients = () => {
  const { t } = useTranslation();

  return (<section id='clients' className="Clients">
    <h2>{t('clients.title')}</h2>
    <ul>
      {cms.clients.map(({ name, logo, url }, idx) => (<li key={idx}>
        <a rel="noopener noreferrer" target="_blank" href={url}>
          <img alt={name} src={`/images/clients/${logo}`} />
        </a>
      </li>))}
    </ul>
  </section>);
};

export default Clients;
