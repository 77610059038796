import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import VideoOgv from '../videos/video.ogv';
import VideoMp4 from '../videos/video.mp4';
import VideoWebm from '../videos/video.webm';

import './video.scss';

const Video = () => {
  const { t } = useTranslation();

  return (<section className="Video">
    <video muted autoPlay loop>
      <source src={VideoOgv} type='video/ogv' />
      <source src={VideoMp4} type='video/mp4' />
      <source src={VideoWebm} type='video/webm' />
    </video>

    <p>{t('video.text')}</p>
  </section>);
};

export default Video;
