import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Hero from '../components/hero';
import About from '../components/about';
import Services from '../components/services';
import Video from '../components/video';
import Clients from '../components/clients';
import Technologies from '../components/technologies';
import Team from '../components/team';
import Brochure from '../components/brochure';
import ContactForm from '../components/contactForm';
import Footer from '../components/footer';

const IndexPage = () => {
  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Infocus | Homepage</title>
      <meta name="keywords" content="Infocus, Data-Mining, información, análisis de datos" />
      <meta name="description" content="La información hace la diferencia"/>
      <meta property="og:title" content="Infocus | Homepage"></meta>
      <meta property="og:description" content="Infocus, Data-Mining, información, análisis de datos"></meta>
    </Helmet>

    <Layout>
      <Hero/>
      <About/>
      <Services/>
      <Video/>
      <Clients/>
      <Technologies/>
      <Team/>
      <Brochure/>
      <ContactForm/>
      <Footer/>
    </Layout>
  </>);
};

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
