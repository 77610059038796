import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import './about.scss';

const About = () => {
  const { t } = useTranslation();

  return (<section id='about' className="About">
    <h2 className="title">{t('about.title')}</h2>
    <p className="description">{t('about.content')}</p>
  </section>);
};

export default About;
